import axios from "axios";

class DiagnosticsService {
  async getDetails () {
    return axios
      .get("api/diagnostics")
      .then(r => r.data)
      .catch(e => console.log(e));
  }

  async getSamlDetails () {
    return axios
      .get("api/diagnostics/saml/v2")
      .then(r => r.data)
      .catch(e => console.log(e));
  }

  async generateException () {
    return axios
      .get("api/diagnostics/GenerateException")
      .then(r => `(${r.status}) ${r.statusText}`)
      .catch(e => e);
  }

  async postStatusCode (code) {
    return axios
      .post("api/diagnostics/HttpStatus", { statusCode: code })
      .then(r => `(${r.status}) ${r.statusText}`)
      .catch(e => e);
  }

  async goToInvalidUrl () {
    return axios
      .get("api/diagnostics/<a>")
      .then(r => `(${r.status}) ${r.statusText}`)
      .catch(e => e);
  }

  async initialPageLoadCompleted () {
    return axios
      .post("api/diagnostics/initialPageLoadCompleted");
  }
}

export default new DiagnosticsService();
