
import { defineComponent, onMounted, computed, ref } from "vue";
import DiagnosticsService from "../services/diagnosticsService";
import { DiagnosticsData, SamlDetails } from "../models/diagnostics/diagnostics";
import DiagnosticsModule from "../store/modules/Diagnostics/diagnostics";

export default defineComponent({
  setup () {
    const loading = ref(true);

    const details = computed(() => {
      const deets = DiagnosticsModule.getSamlDetails;
      const props: DiagnosticsData = [];

      props.push({ name: "Ehr Name", value: deets.EhrName });
      props.push({ name: "Auth Response Type", value: deets.AuthResponseType });
      props.push({ name: "Debug", value: deets.Debug });
      props.push({ name: "Session Id", value: deets.SessionId });
      props.push({ name: "State", value: deets.State });
      props.push({ name: "Config", value: deets.Config });
      props.push({ name: "User", value: deets.User.Id });
      props.push({ name: "Attributes", value: deets.Attributes });
      props.push({
        name: "Token",
        value: [
          { name: "Details", value: deets.TokenDetail },
          { name: "Raw Data", value: deets.RawData },
        ],
      });

      return props;
    });

    const initializePage = () => {
      DiagnosticsService.getSamlDetails().then(res => {
        DiagnosticsModule.setSamlDetails(res as SamlDetails);
        loading.value = false;
      });
    };

    onMounted(() => {
      initializePage();
    });

    return { loading, details };
  },
});
