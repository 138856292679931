/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Details, SamlDetails } from "../../../models/diagnostics/diagnostics";
import store from "@/store/index";
Vue.use(Vuex);

@Module({ name: "Diagnostics", namespaced: true, dynamic: true, store })
class Diagnostics extends VuexModule {
  diagnostics = {} as Details;
  samlDiagnostics = {} as SamlDetails;

  get getDetails () {
    return this.diagnostics;
  }

  get getSamlDetails () {
    return this.samlDiagnostics;
  }

  @Mutation
  SET_DETAILS (details: Details) {
    this.diagnostics = details;
  }

  @Mutation
  SET_SAML_DETAILS (samlDiagnostics: SamlDetails) {
    this.samlDiagnostics = samlDiagnostics;
  }

  @Action
  setDetails (details: Details) {
    this.SET_DETAILS(details);
  }

  @Action
  setSamlDetails (details: SamlDetails) {
    this.SET_SAML_DETAILS(details);
  }
}

export default getModule(Diagnostics);
